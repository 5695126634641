import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { StaticImage , GatsbyImage, getImage } from "gatsby-plugin-image"
 
import SEO from '../components/SEO';
import styled from 'styled-components';


 const ServiceStyles = styled.div`
color: #FFF;
text-align: center;
h1 {
    width: 100%;    
    font-size: 10rem;
    height: 100px;
  }
  p {
    font-size: 2.5rem;
    font-family: FrenchFries;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 15px auto;
    width: 80%;
    .gatsby-image-wrapper {
      margin: 4px;
      height: 40vh;
      flex-grow: 1;
    }
  }
  @media (max-width: 1100px) {
    h1{

      span {
        font-size: 2.5rem;
      }
    }
    p {
    font-size: 1.75rem;
    font-family: FrenchFries;
  }


  }
 `;
 
 export function Hero({children}) {
  return (
    <div style={{ display: "grid" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        aspectRatio={3 / 1.5}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src={
          "https://images.unsplash.com/photo-1604975999044-188783d54fb3?w=2589"
        }
        formats={["auto", "webp", "avif"]}
        
      />
      <div
        style={{
          gridArea: "1/1",
          filter: "opacity(0.4)",
          background: "#062d4c",
        }}></div>
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          padding: "8%",
          
        }}
      >
        <StaticImage src="../assets/images/ELEMENTO.png"
        className=""
        placeholder="none"
        style={{maxWidth:'80px'}}
        width={80}              
        alt="logo" /> 
        {children}
      </div>
    </div>
  )
}

export default function SlicemasterPage({ data, pageContext }) {
  //console.log(data, pageContext )
  const images = data.allImageSharp.nodes.map( n => getImage(n.gatsbyImageData))

  return (
    <ServiceStyles>
      <SEO title={pageContext.name} />      
        <Hero>        
          <h1>
            <span >{pageContext.name}</span>
          </h1>
          <p>{pageContext.description}</p>
        </Hero> 
        <div className="container">
          {
            images && images.map( i => <GatsbyImage image={i} alt={""} />)
          }
        </div>      
    </ServiceStyles>
  );
}

export const MyImgFragment = graphql`
fragment MyImgFragment on ImageSharp {
        gatsbyImageData(
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          height: 640
        )
 }
`
export const pageQuery = graphql`
  query ($regx : String!) {
    allImageSharp(filter: {original: {src: {regex: $regx}}}) {
      nodes {
        id
        ...MyImgFragment
      }
    }
  }
  `; 